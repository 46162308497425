import React from 'react';
import "./style.scss"
import { Link} from "react-router-dom";

const Footer = () => {
    const pathURL = window.location.pathname.split("/")
    const pathURLnow = pathURL[1];
    const pathURL2 = pathURL[2];
    return (
        <>
            {pathURL2 === "get-in-touch" ?
                <>
                    {""}
                </>
                :
                <>
                    {pathURLnow === "spield-algorithm" ? 
                        <>
                            <footer className="footer res">
                                <div className="container">
                                    <div className="algorithm-footer">
                                        <div className="left">
                                            <div className="box-img">
                                                <img src="../../images/logo-res.png" alt="" />
                                            </div>
                                            <div className="txt">
                                                Founded in Toronto; <span>Gathered Industry Elites</span>
                                            </div>
                                            <div className="algorithm-footer-menu">
                                                <a href="#">About us</a>
                                                <a href="#">Careers</a>
                                            </div>
                                            <div className="list-social">
                                                <button type="button" className="btn-ft">
                                                    <a href="/spield-algorithm/get-in-touch">
                                                        Get in touch
                                                    </a>
                                                </button>
                                                <span onClick={() => window.open(`mailto:rleon@spield.ca`)}>
                                                    <img src="./images/email-icon.svg" alt="" />
                                                    leon@spield.ca
                                                </span>
                                                <span onClick={() => window.open(`https://t.me/Spield_Algorithm`)}>
                                                    <img src="./images/tele.png" alt=""  />
                                                    @leongyhk
                                                </span>
                                                
                                                {/* <img src="./images/me.png" alt="" /> */}
                                            </div>
                                        
                                        </div>
                                        
                                    </div>
                                </div>
                            </footer>
                            <div className="algorithm-footer-allright">
                                {/* <ul>
                                    <li></li>
                                </ul> */}
                                    <span>Privacy Policy</span>
                                    <span>Terms of Business</span>
                                    <span>All Right Reserved</span>
                                    <span className="ft-txt">© 2018 - Spield Algorithm</span>
                                
                            </div>
                        </>
                        :
                        <>
                            <footer className="footer">
                                <div className="container">
                                    <div className="main-footer">
                                        <div className="left">
                                            <div className="box-img">
                                                <img src="../../images/logo-spield.png" alt="" />
                                            </div>
                                            <div className="txt">
                                                Founded in Toronto; <span>Gathered Industry Elites</span>
                                            </div>
                                            <div className="list-social">
                                                <button type="button" className="btn-ft">
                                                    <a href="/spield-trading/get-in-touch">
                                                        Get in touch
                                                    </a>
                                                </button>
                                                <span onClick={() => window.open(`mailto:rebeccali@spield.ca`)}>
                                                    <img src="./images/email-icon.svg" alt="" />
                                                    rebeccali@spield.ca
                                                </span>
                                                <span onClick={() => window.open(`https://t.me/Spield_Trading`)}>
                                                    <img src="./images/tele.png" alt=""  />
                                                    @Spield_Trading
                                                </span>
                                                <span onClick={() => window.open(`https://twitter.com/SpieldTrading`)}>
                                                    <img src="./images/twiter.svg" alt=""  />
                                                    @SpieldTrading
                                                </span>
                                                
                                                {/* <img src="./images/me.png" alt="" /> */}
                                            </div>
                                            <div className="list-privaci d-desktop">
                                                <span>Privacy Policy</span>
                                                <span>Terms of Business</span>
                                                <span>All Right Reserved</span>
                                                <span className="ft-txt">© 2018 - Spield Trading</span>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="row-menu-ft">
                                                <div className="item">
                                                    <div className="title">
                                                        Our Services
                                                    </div>
                                                    <a href="#">Exchange Listing</a>
                                                    <a href="#">Advisory Consultant</a>
                                                    <a href="#">Liquidity Provision</a>
                                                    <a href="#">Marketing</a>
                                                </div>
                                                <div className="item">
                                                    <div className="title">
                                                        Our Solution
                                                    </div>
                                                    <a href="#">Stabilization</a>
                                                    <a href="#">Risk Management</a>
                                                    <a href="#">Maturity</a>
                                                </div>
                                                <div className="item">
                                                    <div className="title">
                                                        Company
                                                    </div>
                                                    <a href="#">About us</a>
                                                    <a href="#">Careers</a>
                                                </div>
                                                
                                            </div>

                                            <div className="list-privaci d-mobile">
                                                <span>Privacy Policy</span>
                                                <span>Terms of Business</span><br></br>
                                                <span>All Right Reserved</span>
                                                <span className="ft-txt">© 2018 - Spield Trading</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </>
                    }
                </>
            }
        </>
    )
}
export default Footer