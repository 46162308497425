import React from 'react';
import SpieldTrading from './pages/SpieldTrading';
import SpieldAlgorithm from './pages/SpieldAlgorithm';
import Email from './pages/Email';
import Main from "./components/main/Main";
import {
    Route,
    Routes,
    Navigate,
    BrowserRouter as Router,
} from "react-router-dom";
import Home from './pages/Home';
import EmailAlgorithm from './pages/EmailAlgorithm';
import Case from './pages/Case';


function App() {
    return (
        <div className="App">
            <Main>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/spield-trading" element={<SpieldTrading />} />
                    <Route path="/spield-trading/case" element={<Case />} />
                    <Route path="/spield-algorithm" element={<SpieldAlgorithm />} />
                    <Route path="/spield-algorithm/get-in-touch" element={<EmailAlgorithm />} />
                    <Route path="/spield-trading/get-in-touch" element={<Email />} />
                </Routes>
            </Main>
        </div>
    );
}

export default App;
