import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import Marquee from "react-fast-marquee";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { Button, Menu } from 'antd';
import { MenuUnfoldOutlined, CloseOutlined } from '@ant-design/icons';
import 'aos/dist/aos.css';
import "./style.scss"

const SpieldAlgorithm = () => {

    const pathURL = window.location.pathname.split("/")
    const pathURLnow = pathURL[1]

    const [isActive, setActive] = useState(false);
    const handleToggle = () => {
        setActive(!isActive);
    };
    const handleHideMenuMobile = () => {
        setActive(false);
    };

    function SampleNextArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            <div className="img-next" onClick={onClick}>
                <img src="./images/next.png" alt="" />
            </div>
        );
    }

    function SamplePrevArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            <div className="img-prev" onClick={onClick}>
                <img src="./images/prev.png" alt="" />
            </div>
        );
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    useEffect(() => {
        AOS.init();
    },[]);

    return (
        <>
            <header className="header">
                <div className="container">
                    {pathURLnow === "get-in-touch" ?
                        <>
                            <div className="main-header">
                                <div className="left">
                                    <div className="box-img">
                                        <a href="/">
                                            <img src="./images/logo-spield.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="main-header res">
                                <div className="left">
                                    <div className="box-img">
                                        <a href="/">
                                            <img src="./images/logo-res.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="right">
                                    <div className='d-desktop'>
                                        <div className="butotn-row">
                                            <button type="button" className="btn btn-banner">
                                                <a href="/spield-algorithm/get-in-touch">
                                                    GET IN TOUCH
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    {pathURLnow === "spield-algorithm" ? 
                                        <>
                                            {""}
                                        </>
                                        :
                                        <>
                                            <div className='d-mobile'>
                                                <Button type="primary" onClick={handleToggle} className="btn-menu-mobile">
                                                    <MenuUnfoldOutlined />
                                                </Button>
                                            </div>
                                            <div className="menu-mobile">
                                                <Menu
                                                    defaultSelectedKeys={['']}
                                                    defaultOpenKeys={['']}
                                                    mode="inline"
                                                    theme="dark"
                                                    className={!isActive ? 'hide-menu menu-mobile-ul' : 'show-menu menu-mobile-ul'}
                                                >
                                                    <Menu.Item key="11" className='item-menu-clo'>
                                                        <Button type="primary" onClick={handleToggle} className="btn-menu-mobile-close">
                                                            <CloseOutlined />
                                                        </Button>
                                                    </Menu.Item>
                                                    <Menu.Item key="about-us" icon={''}>
                                                        <Link to="#" onClick={handleHideMenuMobile} className="a-menu-mobile">
                                                            About US
                                                        </Link>
                                                    </Menu.Item>
                                                    <Menu.Item key="services" icon={''} title="Docs" onClick={handleHideMenuMobile}>
                                                        <a href='#' className="a-menu-mobile">
                                                            Services
                                                        </a>
                                                    </Menu.Item>
                                                    <Menu.Item key="solutions" icon={''} title="Docs" onClick={handleHideMenuMobile}>
                                                        <a href="#" className="a-menu-mobile">
                                                            Solutions
                                                        </a>
                                                    </Menu.Item>
                                                    <Menu.Item className='mt-30' key="get-in-touch" icon={''} title="Docs" onClick={handleHideMenuMobile}>
                                                        <div className="butotn-row">
                                                            <button type="button" className="btn btn-touch">
                                                                <a href="/spield-algorithm/get-in-touch">
                                                                    GET IN TOUCH
                                                                </a>
                                                            </button>
                                                        </div>
                                                    </Menu.Item>

                                                </Menu>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </header>
            <div className="wrap-section">
                <div className="bg-div">
                    <img src="./images/bg-div.png" alt="" />
                </div>
                <div className="wrap-banner-focus">
                    <div className="container">
                        <div className="content-asset">
                            <div className="title-sm-bn" data-aos="fade-left" data-aos-duration="1000" data-aos-offset="200">
                                Spield Algorithm Asset Management
                            </div>
                            <div className="title-bn" data-aos="fade-left" data-aos-duration="1200" data-aos-offset="200">
                                Focuses on low-risk <br />
                                Absolute return strategies.
                            </div>
                            <div className="desc" data-aos="fade-left" data-aos-duration="1400" data-aos-offset="200">
                                Spield Algorithm is provide value-added services to digital asset holders and reduce systemic risks associated with digital assets.
                            </div>
                            <div className="img">
                                <img src="./images/img-div.png" alt="" />
                            </div>
                            <div className="list-partner-focus" data-aos="fade-left" data-aos-duration="1600" data-aos-offset="200">
                                <Marquee pauseOnHover={true} speed={80}>
                                    <div className="item">
                                        <img src="./images/p-1.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/p-2.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/p-3.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/p-4.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/p-5.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/p-6.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/p-7.png" alt="" />
                                    </div>
                                </Marquee>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap-investment">
                    <div className="img-left-in">
                        <img src="./images/left-in.png" alt="" />
                    </div>
                    <div className="img-right-in">
                        <img src="./images/right-in.png" alt="" />
                    </div>
                    <div className="container">
                        <div className="content-inves">
                            <div className="row-title" data-aos="fade-left" data-aos-duration="1200" data-aos-offset="200">
                                Investment Philosophy
                            </div>
                            <div className="row-desc" data-aos="fade-left" data-aos-duration="1400" data-aos-offset="200">
                                We integrate mature trading philosophies and methods from traditional markets with digital asset assets, developing trading strategies tailored to the characteristics of digital assets, providing value-added services to digital asset holders.
                            </div>
                            <div className="butotn-row" data-aos="fade-left" data-aos-duration="1600" data-aos-offset="200">
                                <button type="button" className="btn btn-banner">
                                    <a href="/spield-algorithm/get-in-touch">
                                        GET IN TOUCH
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap-core">
                    <div className="container">
                        <Slider {...settings}>
                            <div className="row-core" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
                                <div className="columns">
                                    <div className="colum w-6">
                                        <div className="content-core res">
                                            <div className="title">
                                                Core Strategies
                                            </div>
                                            <div className="desc-sm">
                                                Funding Rate and Cash-Futures Arbitrage
                                            </div>
                                            <div className="desc-sl">
                                                The digital asset market provides numerous risk-free arbitrage opportunities. We utilize long-short hedging combinations to eliminate systemic price volatility risk in the cryptocurrency market. Additionally, we take advantage of the differences in funding and lending rates between different cryptocurrency pairs to earn higher interest income through "carry trades."
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-6">
                                        <div className="content-core">
                                            <div className="box-img">
                                                <img src="./images/img-core.png" alt="" />
                                            </div>
                                            <div className="icon">
                                                <img src="./images/icon-core.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-core">
                                <div className="columns">
                                    <div className="colum w-6">
                                        <div className="content-core res">
                                            <div className="title">
                                                Core Strategies
                                            </div>
                                            <div className="desc-sm">
                                                USDT/BTC/ETH Coin-based CTA Enhanced Strategy
                                            </div>
                                            <div className="desc-sl">
                                                By utilizing machine learning algorithms to discover more effective factors and incorporating the volatility and short to medium-term trends of the digital market, this strategy includes intraday trend-following and reversal CTA as well as short to medium-term predictive CTA.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-6">
                                        <div className="content-core">
                                            <div className="box-img">
                                                <img src="./images/img-core.png" alt="" />
                                            </div>
                                            <div className="icon">
                                                <img src="./images/icon-core.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-core">
                                <div className="columns">
                                    <div className="colum w-6">
                                        <div className="content-core res">
                                            <div className="title">
                                                Core Strategies
                                            </div>
                                            <div className="desc-sm">
                                                Long-Short Value Rotation CTA
                                            </div>
                                            <div className="desc-sl">
                                                Through a programmatic system and in-depth analysis of big data, this strategy conducts real-time scanning of various cryptocurrencies to identify pairs with the highest and lowest relative spreads, facilitat- ing long-short hedging pairs trading. The strategy capitalizes on the advantages of big data analysis, effectively mitigates systemic risks in the cryptocurrency market, and implements cross-asset trend-following CTA, capturing significant trends among different cryptocurrencies.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-6">
                                        <div className="content-core">
                                            <div className="box-img">
                                                <img src="./images/img-core.png" alt="" />
                                            </div>
                                            <div className="icon">
                                                <img src="./images/icon-core.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
                <div className="wrap-basic">
                    <div className="container">
                        <div className="content-basic">
                            <div className="title"  data-aos="fade-left" data-aos-duration="1000" data-aos-offset="200">
                                Business Model
                            </div>
                            <div className="list-content">
                                <div className="item" data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200">
                                    <div className="row">
                                        Client Segregated Account Managed Trading: In this approach, Spield Algo Trading acts as an investment advisor and executes trading strategies on behalf of the client. The client does not intervene or conduct trades themselves. The assets in the account belong solely to the client, and profits are settled on a regular basis.
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row"  data-aos="fade-up" data-aos-duration="1400" data-aos-offset="200">
                                        Client Investment through Designated Third-Party Custodial Account: In this approach, clients invest their respective digital assets into a third-party custodial account designated by Spield Algo Trading for trading purposes. The trading is done based on the net asset value (NAV), and clients can execute subscription (purchase) and redemption (withdrawal) transactions accordingly.
                                    </div>
                                </div>
                            </div>
                            <div className="img-square" data-aos="fade-up" data-aos-duration="1600" data-aos-offset="200">
                                <img src="./images/map-business-model.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap-risk">
                    <div className="bg-risk">
                        <img src="./images/bg-risk.png" alt="" />
                    </div>
                    <div className="container">
                        <div className="content-risk">
                            <div className="title" data-aos="fade-left" data-aos-duration="1000" data-aos-offset="200">
                                Risk management and control
                            </div>
                            <div className="row-risk">
                                <div className="row-net" data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200">
                                    <div className="desc">
                                        Spield Algo Trading employs a neutral strategy, strictly adhering to complete hedging of short positions, thereby maintaining a market-neutral overall digital asset portfolio. This approach ensures that the portfolio is not significantly affected by sharp price fluctuations in the assets, allowing for steady and robust appreciation.
                                    </div>
                                </div>
                                <div className="row-net res" data-aos="fade-up" data-aos-duration="1400" data-aos-offset="200">
                                    <div className="desc">
                                        Spield Algo Trading employs a neutral strategy, strictly adhering to complete hedging of short positions, thereby maintaining a market-neutral overall digital asset portfolio. This approach ensures that the portfolio is not significantly affected by sharp price fluctuations in the assets, allowing for steady and robust appreciation.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap-our-team">
                    <div className="container">
                        <div className="content-our-team">
                            <div className="title" data-aos="fade-left" data-aos-duration="1200" data-aos-offset="200">
                                Our Team
                            </div>
                            <div className="columns">
                                <div className="colum w-3">
                                    <div className="item" data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200">
                                        <div className="box-img">
                                            <img src="./images/name-2.png" alt="" />
                                        </div>
                                        <div className="name">
                                            Leon
                                        </div>
                                        <div className="txt">
                                            CFA Co-founder
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-3">
                                    <div className="item" data-aos="fade-up" data-aos-duration="1300" data-aos-offset="200">
                                        <div className="box-img">
                                            <img src="./images/name-4.png" alt="" />
                                        </div>
                                        <div className="name">
                                            Alan
                                        </div>
                                        <div className="txt">
                                            Co-founder
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-3">
                                    <div className="item" data-aos="fade-up" data-aos-duration="1400" data-aos-offset="200">
                                        <div className="box-img">
                                            <img src="./images/name-3.png" alt="" />
                                        </div>
                                        <div className="name">
                                            Michael
                                        </div>
                                        <div className="txt">
                                            Portfolio Manager
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-3">
                                    <div className="item" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="200">
                                        <div className="box-img">
                                            <img src="./images/name-1.png" alt="" />
                                        </div>
                                        <div className="name">
                                            Simon
                                        </div>
                                        <div className="txt">
                                            Chief Quantitative Strategist
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-3">
                                    <div className="item" data-aos="fade-up" data-aos-duration="1600" data-aos-offset="200">
                                        <div className="box-img">
                                            <img src="./images/name-5.png" alt="" />
                                        </div>
                                        <div className="name">
                                            Eden
                                        </div>
                                        <div className="txt">
                                            Risk Director
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-3">
                                    <div className="item" data-aos="fade-up" data-aos-duration="1700" data-aos-offset="200">
                                        <div className="box-img">
                                            <img src="./images/name-6.png" alt="" />
                                        </div>
                                        <div className="name">
                                            Kayla
                                        </div>
                                        <div className="txt">
                                            Investment Manager
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-3">
                                    <div className="item" data-aos="fade-up" data-aos-duration="1800" data-aos-offset="200">
                                        <div className="box-img">
                                            <img src="./images/name-7.png" alt="" />
                                        </div>
                                        <div className="name">
                                            Victor
                                        </div>
                                        <div className="txt">
                                            Quantativer Trader
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-3">
                                    <div className="item" data-aos="fade-up" data-aos-duration="1900" data-aos-offset="200">
                                        <div className="box-img">
                                            <img src="./images/name-8.png" alt="" />
                                        </div>
                                        <div className="name">
                                            Bryan
                                        </div>
                                        <div className="txt">
                                            Quantative Analyst
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SpieldAlgorithm