import React, { useState , useEffect } from 'react';
import AOS from 'aos';
import { Link} from "react-router-dom";
import { Button, Col, Menu, Row } from 'antd';
import { MenuUnfoldOutlined, CloseOutlined, ArrowRightOutlined } from '@ant-design/icons';
import 'aos/dist/aos.css';

const Home = () => {

    // useEffect(() => {
    //     AOS.init();
    // },[]);

    return (
        <>
            <div className='block-overlay-star'></div>
            <div className='block-overlay-green'></div>
            <div className="main-page">
                <div className='p-main'>
                    <div className='home-header'>
                        <div className='header-logo'>
                            <a href=''><img src='../images/spield-home.svg' /></a>
                        </div>
                    </div>
                    
                    <div className='home-content'>
                        <div className='title'>
                            Customized Crypto Trading <br></br> Strategy Service
                        </div>
                        <div className='description'>
                            Toronto Based, Industry Elites, Net Profit-Driven, <br></br> Reputation-focused, Mutual Success
                        </div>
                        <div className='block-list'>
                            <Row gutter={16}>
                                <Col className="gutter-row" xs={24} sm={12} md={12}>
                                    <div className='block-item green' onClick={() => window.open('/spield-trading')}>
                                        <div className='item-logo'>
                                            <img src='../images/item-trading-logo.svg' />
                                        </div>
                                        <div className='item-title'>
                                            TRADING SERVICES
                                        </div>
                                        <div className='item-description'>
                                            Market Value Management and Quantitive Tradings
                                        </div>
                                        <div className='item-viewmore'>
                                            <a className='text-green' href='/spield-trading'>Learn more <ArrowRightOutlined /></a>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} sm={12} md={12}>
                                    <div className='block-item yellow' onClick={() => window.open('/spield-algorithm')}>
                                        <div className='item-logo'>
                                            <img src='../images/item-algorithrm-logo.svg' />
                                        </div>
                                        <div className='item-title'>
                                            Asset Management
                                        </div>
                                        <div className='item-description'>
                                            Focuses on low-risk, absolute return strategies.
                                        </div>
                                        <div className='item-viewmore'>
                                            <a className='text-yellow' href='/spield-algorithm'>Learn more <ArrowRightOutlined /></a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            
                        </div>
                        
                    </div>
                    <div className='home-menu'>
                        <div className='p-footer'>
                        <ul>
                            <li><a href="">Privacy Policy</a></li>
                            <li><a href="">Terms of Business</a></li>
                            <li><a href=""><span>© 2018 - Spield</span></a></li>
                        </ul>
                    </div>
                    </div>
                    <div className='block-overlay'>
                        <video id="video-play" width="100%" height="100%" autoPlay loop muted>
                            <source src="../images/video-overlay.mp4" type="video/mp4" />
                            <source type="video/webm" src="../images/video-overlay.mp4" />
                            <source src="../images/video-overlay.mp4" type="video/ogg" />
                        </video>
                    </div>
                    
                </div>
                <div className='footer-copyright'>
                    <span>© 2018 - Spield Algorithm</span>
                </div>
                
            </div>
        </>
    )
}
export default Home