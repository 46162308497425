import React, { useEffect } from 'react';
import { Link} from "react-router-dom";

const EmailAlgorithm = () => {
    return (
        <>
            <header className="header">
                <div className="container">
                    <div className="main-header">
                        <div className="left">
                            <div className="box-img">
                                <a href="/spield-algorithm">
                                    <img src="../images/logo-res.png" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="wrap-email">
                <div className='d-desktop'>
                
                    <div className="banner-email-2">
                        <img src="../images/banner-email-2.png" alt="" />
                    </div>
                </div>
                <div className="container">
                    <div className="content-email">
                        <div className="title">
                            Contact us at Telegram
                        </div>
                        <div className="desc" onClick={() => window.open(`https://t.me/Spield_Algorithm`)}>
                            @leongyhk
                        </div>
                        <div className="desc-email">
                            Or leave your Telegram username and we reply to you shortly
                        </div>
                        <div className="input-email">
                            <input type="text" placeholder="Enter your @telegram" />
                            <button type="button">
                                SEND <img src="../images/arrow-banner.png" alt="" />
                            </button>
                        </div>
                        <div className="email-contact">
                            or Contact us at Email <span>leon@spield.ca<img src="../images/copied.png" alt="" /></span>
                        </div>
                    </div>
                </div>
                <div className='d-mobile'>
                    
                    <div className="banner-email-2 posi-realted">
                        <img src="../images/banner-email-2.png" alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}
export default EmailAlgorithm