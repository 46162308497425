import React, { useState , useEffect } from 'react';
import AOS from 'aos';
import { Link} from "react-router-dom";
import { Button, Col, Menu, Row } from 'antd';
import { MenuUnfoldOutlined, CloseOutlined } from '@ant-design/icons';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'aos/dist/aos.css';

const SpieldTrading = () => {

    const pathURL = window.location.pathname.split("/")
    const pathURLnow = pathURL[1]

    const [isActive, setActive] = useState(false);
    const handleToggle = () => {
        setActive(!isActive);
    };
    const handleHideMenuMobile = () => {
        setActive(false);
    };

    function SampleNextArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            <div className="img-next" onClick={onClick}>
                <img src="./images/next.png" alt="" />
            </div>
        );
    }

    function SamplePrevArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            <div className="img-prev" onClick={onClick}>
                <img src="./images/prev.png" alt="" />
            </div>
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1
    };

    useEffect(() => {
        AOS.init();
    },[]);

    return (
        <>
            <header className="header" >
                <div className="container">
                    {pathURLnow === "get-in-touch" ?
                        <>
                            <div className="main-header">
                                <div className="left">
                                    <div className="box-img">
                                        <a href="/">
                                            <img src="./images/logo-neww.svg" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="main-header">
                                <div className="left">
                                    <div className="box-img">
                                        <a href="/">
                                            <img src="./images/logo-spield.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="right">
                                    <div className='d-desktop'>
                                        <div className="main-menu">
                                            <ul className="list-menu">
                                                <li>
                                                    <a href="#">
                                                        About US
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/spield-trading/case">
                                                        CASES
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        Services
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        Solutions
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="butotn-row">
                                            <button type="button" className="btn btn-touch">
                                                <a href="/spield-trading/get-in-touch">
                                                            GET IN TOUCH
                                                        </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='d-mobile'>
                                        <Button type="primary" onClick={handleToggle} className="btn-menu-mobile">
                                            <MenuUnfoldOutlined />
                                        </Button>
                                    </div>
                                    <div className="menu-mobile">
                                        <Menu
                                            defaultSelectedKeys={['']}
                                            defaultOpenKeys={['']}
                                            mode="inline"
                                            theme="dark"
                                            className={!isActive ? 'hide-menu menu-mobile-ul' : 'show-menu menu-mobile-ul'}
                                        >
                                            <Menu.Item key="11" className='item-menu-clo'>
                                                <Button type="primary" onClick={handleToggle} className="btn-menu-mobile-close">
                                                    <CloseOutlined/>
                                                </Button>
                                            </Menu.Item>
                                            <Menu.Item key="about-us" icon={''}>
                                                <Link to="#" onClick={handleHideMenuMobile} className="a-menu-mobile">
                                                    About US
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key="case" icon={''}>
                                                <Link to="/spield-trading/case" onClick={handleHideMenuMobile} className="a-menu-mobile">
                                                    CASES
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key="services" icon={''} title="Docs" onClick={handleHideMenuMobile}>
                                                <a href='#' className="a-menu-mobile">
                                                    Services
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item key="solutions" icon={''} title="Docs" onClick={handleHideMenuMobile}>
                                                <a href="#" className="a-menu-mobile">
                                                    Solutions
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item className='mt-30' key="get-in-touch" icon={''} title="Docs" onClick={handleHideMenuMobile}>
                                                <div className="butotn-row">
                                                    <button type="button" className="btn btn-touch">
                                                        <a href="/spield-trading/get-in-touch">
                                                            GET IN TOUCH
                                                        </a>
                                                    </button>
                                                </div>
                                            </Menu.Item>

                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </header>
            <div className="wrap-section">
                <div className="wrap-banner">
                    {/* <div className="banner-bottom">
                        <img src="./images/banner-left.png" alt="" />
                    </div> */}
                    {/* <div className="banner-top">
                        <img src="./images/banner-top.png" alt="" />
                    </div> */}
                    <div className="banner-right">
                        <img src="./images/banner-right.png" alt="" />
                    </div>
                    <div className="container">
                        <div className="main-banner">
                            <div className="title-wrap" data-aos="fade-left" data-aos-duration="800" data-aos-offset="200">
                                Spield TRADING
                            </div>
                            <div className="title" 
                                data-aos="fade-left" data-aos-duration="1000" data-aos-offset="200">
                                Market Value Management and Quantitative Trading
                            </div>
                            <div className="desc"  
                                data-aos="fade-left" data-aos-duration="1500" data-aos-offset="200">
                                Spield Trading powers leading institutions globally through best-in-class trading technology and services.
                            </div>
                            <div className="butotn-row"
                                data-aos="fade-left" data-aos-duration="2000" data-aos-offset="200">
                                <button type="button" className="btn btn-banner bg-white">
                                    <a href="/spield-trading/get-in-touch">
                                        GET IN TOUCH <img src="./images/arrow-banner.png" alt="" />
                                    </a> 
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="wrap-making">
                    <div className="main-making bg-green">
                        <div className='container'>
                            <div className="guide-colum">
                                <div className="title-mk" data-aos="fade-right" data-aos-duration="1200" data-aos-offset="200">
                                    Market Making and Market Value Management
                                </div>
                                <div className="desc-mk" data-aos="fade-right" data-aos-duration="1400" data-aos-offset="200">
                                    With our services, many project parties have produced many star projects on a lot of well-known CEX & DEX Platforms
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row-making">
                        <div className='container'>
                            <Row gutter={16}>
                                <Col className="gutter-row" xs={24} sm={6} md={6}>
                                    <div className='mk-item' data-aos="fade-right" data-aos-duration="1200" data-aos-offset="200">
                                        <div className='mk-number'>$100M</div>
                                        <div className='mk-title'>Funds</div>
                                        <div className='mk-description'>Raised $100M web3 growth fund to invest entirely in digital assets</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} sm={6} md={6}>
                                    <div className='mk-item' data-aos="fade-right" data-aos-duration="1300" data-aos-offset="200">
                                        <div className='mk-number'>300+</div>
                                        <div className='mk-title'>Clients</div>
                                        <div className='mk-description'>Spield Trading cooperates with hundreds of partners in the field of digital assets and establish appropriate pricing strategies</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} sm={6} md={6}>
                                    <div className='mk-item' data-aos="fade-right" data-aos-duration="1400" data-aos-offset="200">
                                        <div className='mk-number'>1,000B+</div>
                                        <div className='mk-title'>Trade volume</div>
                                        <div className='mk-description'>The accumulated trade volume over time has reached more than 1,000 billion, creating attraction for customers.</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} sm={6} md={6}>
                                    <div className='mk-item' data-aos="fade-right" data-aos-duration="1500" data-aos-offset="200">
                                        <div className='mk-number'>50+</div>
                                        <div className='mk-title'>Team members</div>
                                        <div className='mk-description'>Extensive technical background, experienced and smart.</div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='making-banner'>
                        <img src='../images/banner-making.png'/>
                    </div>
                </div> */}
                <div className="wrap-making">
                    <div className="banner-making">
                        <img src="./images/banner-making.png" alt="" />
                    </div>
                    <div className="banner-making-top">
                        <img src="./images/banner-mk-top.png" alt="" />
                    </div>
                    <div className="container">
                        <div className="main-making">
                            {/* <div className="title-small" data-aos="fade-right" data-aos-duration="1000" data-aos-offset="200">
                                Spield Case Trading Examples
                            </div> */}
                            <div className="row-content">
                                <div className="columns">
                                    <div className="colum w-4">
                                        <div className="guide-colum">
                                            <div className="title-mk" data-aos="fade-right" data-aos-duration="1200" data-aos-offset="200">
                                                Market Making and Market Value Management
                                            </div>
                                            <div className="desc-mk" data-aos="fade-right" data-aos-duration="1400" data-aos-offset="200">
                                                With our services, many project parties have produced many star projects on a lot of well-known CEX & DEX Platforms
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-8">
                                        <div className="row-content-pres">
                                            <div className="columns">
                                                <div className="colum w-6 d-desktop">
                                                    <div className="guide-colum res row">
                                                        <div className="number" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
                                                            $100M
                                                        </div>
                                                        <div className="number" data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200">
                                                            300+
                                                        </div>
                                                        <div className="number" data-aos="fade-up" data-aos-duration="1400" data-aos-offset="200">
                                                            1,000B+
                                                        </div>
                                                        <div className="number" data-aos="fade-up" data-aos-duration="1400" data-aos-offset="200">
                                                            50+
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="colum w-6">
                                                    <div className="guide-colum res">
                                                        <ul className="list-row">
                                                            <li data-aos="fade-left" data-aos-duration="1000" data-aos-offset="200">
                                                                <div className="number d-mobile">
                                                                    $100M
                                                                </div>
                                                                <div className="txt-top">
                                                                    Funds
                                                                </div>
                                                                <div className="txt-bottom">
                                                                    Raised $100M web3 growth fund to invest entirely in digital assets
                                                                </div>
                                                            </li>
                                                            <li data-aos="fade-left" data-aos-duration="1200" data-aos-offset="200">
                                                                <div className="number d-mobile">
                                                                    300+
                                                                </div>
                                                                <div className="txt-top">
                                                                    Clients
                                                                </div>
                                                                <div className="txt-bottom">
                                                                    Spield Trading cooperates with hundreds of partners in the field of digital assets and establish appropriate pricing strategies
                                                                </div>
                                                            </li>
                                                            <li data-aos="fade-left" data-aos-duration="1400" data-aos-offset="200">
                                                                <div className="number d-mobile">
                                                                    1,000B+
                                                                </div>
                                                                <div className="txt-top">
                                                                    Trade volume
                                                                </div>
                                                                <div className="txt-bottom">
                                                                    The accumulated trade volume over time has reached more than 1,000 billion, creating attraction for customers.
                                                                </div>
                                                            </li>
                                                            <li data-aos="fade-left" data-aos-duration="1400" data-aos-offset="200">
                                                                <div className="number d-mobile">
                                                                    50+
                                                                </div>
                                                                <div className="txt-top">
                                                                    Team members
                                                                </div>
                                                                <div className="txt-bottom">
                                                                    Extensive technical background, experienced and smart.
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrap-cases-participated">
                    <div className="container">
                        <div className='participated-title'>Cases</div>
                        <div className='participated-block'>
                        
                            <Row gutter={16} justify="center">
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/near.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/solana.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/polygon.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/polkadot.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/secondlive.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/flow.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/chia.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/avalanche.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/bscs.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/trustwallet.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/vext.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/combo.svg'/></a>
                                    </div>
                                </Col>
                                
                            </Row>
                        </div>
                        <div className='participated-block-mobile'>
                            <Slider {...settings}>
                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/near.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/solana.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/polygon.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/polkadot.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/secondlive.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/flow.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/chia.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/avalanche.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/bscs.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/trustwallet.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>
    
                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/vext.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/combo.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>
                                
                            </Slider>
                        </div>
                        <div className='butotn-row text-center mt-15'>
                            <button className="btn btn-touch" onClick={()=> window.location.replace('spield-trading/case')}>
                                SHOW MORE
                            </button>
                        </div>
                    </div>     
                </div>

                <div className="wrap-partner">
                   
                    <div className="banner-partner">
                        <img src="./images/banner-partner.png" alt="" />
                    </div>
                    <div className="container">
                        <div className="main-partner">
                            <div className="title-small" data-aos="fade-right" data-aos-duration="1000" data-aos-offset="200">
                                PARTNER EXCHANGES
                            </div>
                            <div className="title" data-aos="fade-right" data-aos-duration="1200" data-aos-offset="200">
                                Spield Trading is a tight-knit community of successful Web3 founders who support and empower each other to achieve greatness
                            </div>
                            <div className="desc" data-aos="fade-right" data-aos-duration="1400" data-aos-offset="200">
                                We work collaboratively to build the infrastructure of the future and are committed to <br /> transparency and trust in all our business dealings.Join us and become part of the Spield Trading family today.
                            </div>
                            <div className="butotn-row" data-aos="fade-right" data-aos-duration="1600" data-aos-offset="200">
                                <button type="button" className="btn btn-touch">
                                    <a href="/spield-trading/get-in-touch">
                                        GET IN TOUCH
                                    </a>
                                </button>
                            </div>
                            <ul className="list-partner">
                                <li data-aos="zoom-in-up" data-aos-duration="1000" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/p-1.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/r-2.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/p-3.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/p-4.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/logo_htx.svg" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/p-6.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/r-7.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/p-8.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/p-9.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/r-10.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/r-11.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/r-12.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/p-13.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/r-14.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/r-15.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/r-16.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/r-17.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/ray.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/r-19.png" alt="" />
                                    </a>
                                </li>
                                <li data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                    <a href="#">
                                        <img src="./images/logo_xt.svg" alt="" />
                                    </a>
                                </li>
                            </ul>
                            <p className="number-more"  data-aos-duration="1200" data-aos-offset="200">
                                AND 100+ MORE
                            </p>
                        </div>
                    </div>
                </div>
                <div className="wrap-maturity">
                    <div className="container">
                        <div className="main-maturity">
                            <div className="item" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
                                <div className="number">
                                    01
                                </div>
                                <div className="title">
                                    Stabilization
                                </div>
                                <ul className="list-matu">
                                    <li>
                                        300 consecutive trading days without incidents 
                                    </li>
                                    <li> 
                                        Accumulate Market
                                    </li>
                                    <li>
                                        Making returns continuously
                                    </li>
                                </ul>
                            </div>
                            <div className="item" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
                                <div className="number">
                                    02
                                </div>
                                <div className="title">
                                    Risk Management
                                </div>
                                <ul className="list-matu">
                                    <li>
                                        Immediate warning and response to market fluctuations 
                                    </li>
                                    <li>
                                        A Real-time detection of Exchange's API
                                    </li>
                                </ul>
                            </div>
                            <div className="item" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
                                <div className="number">
                                    03
                                </div>
                                <div className="title">
                                    Maturity
                                </div>
                                <ul className="list-matu">
                                    <li>
                                        Model structure is practical and effective to facillitate tradings 
                                    </li>
                                    <li>
                                        No deviation between simulation and reality
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap-project">
                    <div className="container">
                        <div className="main-project">
                            <div className="title-small" data-aos="fade-right" data-aos-duration="1000" data-aos-offset="200">
                                OUR SERVICES
                            </div>
                            <div className="title" data-aos="fade-right" data-aos-duration="1200" data-aos-offset="200">
                                Spield Trading offers comprehensive solutions for digital asset issuers at all stages.
                            </div>
                            <div className="row-project">
                                <div className="columns">
                                    <div className="colum w-3" data-aos="zoom-in-up" data-aos-duration="1200" data-aos-offset="200">
                                        <div className="content-row" >
                                            <div className="card-banner">
                                                <img src="./images/card-banner.png" alt="" />
                                            </div>
                                            <div className="number">
                                                01
                                            </div>
                                            <div className="icon">
                                                <img src="./images/i-1.png" alt="" />
                                            </div>
                                            <div className="txt-top">
                                                Exchange Listing
                                            </div>
                                            <div className="txt-bottom">
                                                We support fast listing processes with market leading exchanges to deliver quick and cost-effective results.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-3" data-aos="zoom-in-up" data-aos-duration="1400" data-aos-offset="200">
                                        <div className="content-row">
                                            <div className="card-banner">
                                                <img src="./images/card-banner.png" alt="" />
                                            </div>
                                            <div className="number">
                                                02
                                            </div>
                                            <div className="icon">
                                                <img src="./images/i-2.png" alt="" />
                                            </div>
                                            <div className="txt-top">
                                                Advisory Consultant
                                            </div>
                                            <div className="txt-bottom">
                                                Providing consulting on blockchain, asset tokenization, blockchain development, tokenomics and go to market strategy.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-3" data-aos="zoom-in-up" data-aos-duration="1600" data-aos-offset="200">
                                        <div className="content-row">
                                            <div className="card-banner">
                                                <img src="./images/card-banner.png" alt="" />
                                            </div>
                                            <div className="number">
                                                03
                                            </div>
                                            <div className="icon">
                                                <img src="./images/i-3.png" alt="" />
                                            </div>
                                            <div className="txt-top">
                                                Liquidity Provision
                                            </div>
                                            <div className="txt-bottom">
                                                Enhance token liquidity through appropriate algorithmic and trading strategies that positively impact project value.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-3" data-aos="zoom-in-up" data-aos-duration="1800" data-aos-offset="200">
                                        <div className="content-row">
                                            <div className="card-banner">
                                                <img src="./images/card-banner.png" alt="" />
                                            </div>
                                            <div className="number">
                                                04
                                            </div>
                                            <div className="icon">
                                                <img src="./images/i-4.png" alt="" />
                                            </div>
                                            <div className="txt-top">
                                                Marketing
                                            </div>
                                            <div className="txt-bottom">
                                                We are using many different marketing tools to promote the brand and attract investors and users to build a brand for your project.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="butotn-row" data-aos="zoom-in-up" data-aos-duration="2000" data-aos-offset="200">
                                <button type="button" className="btn btn-banner bg-white">
                                    <a href="/spield-trading/get-in-touch">
                                        GET IN TOUCH
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SpieldTrading