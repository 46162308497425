import React, { useEffect, memo } from "react";
import { Layout } from "antd";

import Header from "../Header";
import Footer from "../Footer";

const { Content } = Layout;
function Main({ children }: any) {
    const pathURL = window.location.pathname.split("/");
    const pathURLnow:any = pathURL[1];
    return (
        <Layout>
            <Content>{children}</Content>
            {pathURLnow !== '' && (
                <Footer />
            )}
        </Layout>
    );
}

export default Main;
